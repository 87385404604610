<template>
  <div>
    <!-- ADD BUTTON -->
    <b-row class="text-right m-2">
      <b-button variant="primary">
        <feather-icon icon="PlusCircleIcon" /> Venda
      </b-button>
    </b-row>
    <b-row>
      <!-- CATEGORIA -->
      <b-col md="3">
        <b-card header-tag="header">
          <template #header class="text-center">
            <h6>RADAR</h6>
          </template>
          <b-list-group v-for="venda in radar" :key="venda.id" class="mt-1 mx-0">
            <b-list-group-item
              variant="primary"
              href="#"
              @click="linkToPerfil(venda.id)"
            >
              <p class="m-0">
                {{ venda.produto }} {{ venda.quadra }}-{{ venda.lote }}
                <small>{{ venda.clientId }}</small>
              </p>
              <small
                ><b>{{ venda.imob }}</b></small
              >
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>

      <!-- CATEGORIA -->
      <b-col md="3">
        <b-card header-tag="header">
          <template #header class="text-center">
            <h6>DOCUMENTAÇÃO</h6>
          </template>
        </b-card>
      </b-col>

      <!-- CATEGORIA -->
      <b-col md="3">
        <b-card header-tag="header">
          <template #header class="text-center">
            <h6>ASSINAR</h6>
          </template>
        </b-card>
      </b-col>

      <!-- CATEGORIA -->
      <b-col md="3">
        <b-card header-tag="header">
          <template #header class="text-center">
            <h6>VENDA</h6>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
  BBadge,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  data() {
    return {
      radar: [],
    };
  },
  methods: {
    getSales() {
      this.radar = [
        {
          id: "123",
          produto: "BSB1",
          quadra: "R",
          lote: "09",
          clientId: "#20292",
          imob: "Alpha 300",
        },
        {
          id: "123",
          produto: "BSB1",
          quadra: "R",
          lote: "09",
          clientId: "#20292",
          imob: "Alpha 300",
        },
      ];
    },
    linkToPerfil(id) {
      this.$router.push(`vendas/${id}`)
    },
  },
  mounted() {
    this.getSales();
  },
};
</script>

<style>
</style>